@tailwind components;

@layer components {
  .button-main[disabled],
  .button-sec[disabled] {
    @apply !bg-zinc-100 !text-zinc-500 cursor-not-allowed;
  }

  .button-sec[disabled] {
    @apply border-zinc-200;
  }

  .button {
    @apply cursor-pointer;
  }

  .button.as-button {
    @apply shadow-sm rounded text-center no-underline;
  }
  .button.as-link {
    @apply text-center underline items-center;
  }

  .button.as-button.main {
    @apply bg-sky-500  text-white
              hover:bg-sky-400;
  }
  .button.as-button.sec {
    @apply text-sky-500  bg-white
              border border-sky-100
              hover:bg-sky-50;
  }

  .button.as-button.danger {
    @apply text-white  bg-red-700
              hover:bg-red-500;
  }

  .button.as-button.disabled {
    @apply !bg-zinc-200 !text-zinc-400 !border-zinc-200 !cursor-not-allowed;
  }

  .button.as-link.main,
  .button.as-link.sec {
    @apply text-sky-500
              hover:text-sky-400;
  }

  .button.as-link.danger {
    @apply text-red-700
              hover:text-red-500;
  }

  .button.sm {
    @apply text-sm;
  }

  .button.md {
    @apply text-sm;
  }
  .button.lg {
    @apply text-lg;
  }
  .button.as-button.sm {
    @apply py-1 px-2;
  }

  .button.as-button.md {
    @apply py-2 px-4;
  }
  .button.as-button.lg {
    @apply py-2 px-4;
  }
}
